<!-- 申请转入页面 -->
<template>
  <div :class="['changeInto-view', showFooter ? 'changeInto-view-bottom' : '']">
    <div class="common-block">
      <div class="common-t">
        <div class="common-t-l">申请转入线索</div>
      </div>
      <van-cell v-if="examine" title="申请转入门店" :value=detail.targetDealerName />
      <van-cell title="客户姓名" :value=detail.userName />
      <van-cell title="联系电话" :value=detail.userMobile />
      <van-cell title="客户所在门店" :value=detail.orgDealerName />
    </div>
    <div class="common-block">
      <div class="common-t">
        <div class="common-t-l">申请说明</div>
      </div>
      <van-form ref="applyForm" input-align="right" error-message-align="right">
        <van-field
          v-model="applyParams.remark"
          rows="2"
          required
          autosize
          label="申请说明"
          :readonly="examine ? true : false"
          type="textarea"
          maxlength="200"
          placeholder="可输入不超过200字的申请说明"
          show-word-limit
          :rules="[{ required: true }]"
        />
        <van-cell title="附件"></van-cell>
        <van-uploader
          class="upload"
          capture="camera"
          accept="image/*"
          :preview-options="{closeable: true}"
          :before-read="beforeRead"
          :deletable="!examine"
          :after-read="(file) => afterRead(file)"
          :file-list="fileList"
          :before-delete="(file) => beforeDelete(file)"
          :max-count="!examine?5:fileList.length"
          />
      </van-form>
    </div>
    <div v-if="showFooter" class="common-footer">
      <van-button class="btn" @click="reject">{{ examine ? $t('拒绝') : $t('取消') }}</van-button>
      <van-button class="btn submit" :loading="submitLoading" @click="onSubmit">{{ examine ? $t('同意') : $t('提交') }}</van-button>
    </div>
    <!-- 拒绝原因填写 -->
    <van-dialog v-model="dialogShow" title="审核意见" 
      show-cancel-button 
      :cancel-button-text="$t('取消')" 
      :confirm-button-text="$t('确认')"
      :close-on-click-overlay="true"
      :before-close="closeDialog"
      confirmButtonColor="#B9921A">
      <van-form ref="dialogForm" validate-trigger="onSubmit" input-align="right" error-message-align="right">
        <van-field
          v-model="examineParams.refuseReason"
          required
          rows="2"
          autosize
          :label="$t('审核意见')"
          type="textarea"
          maxlength="50"
          :placeholder="$t('请输入审核意见')"
          :rules="[{ required: true }]"
          show-word-limit
        />
      </van-form>
    </van-dialog>
  </div>
</template>
<script>
import leadChangeIntoServices from '@/services/leadChangeIntoServices'
import loading from '@/utils/loading'
import { v4 as uuidv4 } from 'uuid'
import { uploadFile } from '@/utils/cos'
export default {
  data() {
    return {
      detail: {
        userName: '',
        userMobile: '',
        orgDealerName: '',
        targetDealerName: '', // 申请转入门店
      },
      dialogShow: false,
      // 提交申请参数
      applyParams: {
        leadId: '',
        remark: '',
        attachUrl: '',
      },
      examine: false, // 判断是否是审核
      // 审核参数
      examineParams: {
        id: '',
        refuseReason: '', // 审核意见
        status: '',
      },
      routeStatus: '',
      fileList: [],
      submitLoading: false
    }
  },
  computed: {
    // 判断是否显示底部按钮 1、默认提交 2、可审核
    showFooter() {
      return this.examine === false || (this.routeStatus === this.examineParams.status)
    }
  },
  async mounted() {
    const { id, examine=false, status } = this.$route.query
    this.examine = examine
    this.routeStatus = status // 消息中心跟的当前状态（判断该状态与后续审核详情的状态是否一致 确定是否可以审核 防止重复审核）
    let result = {}
    if (examine) { // 消息中心跳转
      loading.showLoading
      // 获取审核详情
      result = await leadChangeIntoServices.dealerTransferDetail({ id })
      loading.hideLoading()
      this.applyParams.remark = result.remark // 申请备注
      this.examineParams.status = result.status // 审核状态
      this.examineParams.id = id
    } else {
      result = this.$route.query
      this.applyParams.leadId = id
    }
    this.fileList = result.attachUrl ? result.attachUrl.split(',').map((item) => { return { url: item } }) : []
    for (var key in this.detail) {
      if (result[key]) {
        this.detail[key] = result[key]
      }
    }
  },
  methods: {
    // 取消
    reject() {
      if (this.examine) { // 驳回
        this.examineParams.refuseReason = ''
        this.dialogShow = true
      } else { // 取消
        this.$router.go(-1)
      }
    },
    // 确认
    async onSubmit() {
      if (this.examine) { // 审核
        this.review(1)
      } else { // 提交
        this.$refs.applyForm.validate().then(async () => {
          if (this.submitLoading) return false
          this.submitLoading = true
          loading.showLoading()
          this.applyParams.attachUrl = this.fileList.map(({ cosUrl }) => { return cosUrl }).join(',')
          await leadChangeIntoServices.dealerTransferApply(this.applyParams)
          loading.hideLoading()
          this.submitLoading = false
          this.$toast.success('操作成功！')
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        })
      }
    },
    // 关闭弹窗
    async closeDialog(action, done) {
      if (action === 'confirm'){
        this.$refs.dialogForm.validate().then(async () => {
          this.review(0, done)
        }, done.bind(null, false))
      } else {
        this.dialogShow = false
        done()
      }
    },
    // 审核
    async review(result, done) {
      const { id, refuseReason, status } = this.examineParams
      const params = { id, refuseReason, status, result }
      try {
        if (this.submitLoading) return false
        this.submitLoading = true
        loading.showLoading()
        await leadChangeIntoServices.dealerTransferReview(params)
        loading.hideLoading()
        this.submitLoading = false
        if (done) done()
        window.close()
      } catch {
        if (done) done() 
      }
    },
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','gif']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/png/gif)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = '上传中...'
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
          this.fileList.push(file)
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
  }
}
</script>
<style lang="less" scoped>
.changeInto-view{
  padding: 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  &.changeInto-view-bottom{
    padding-bottom: 96px;
  }
  .common-block{
    margin-bottom: 16px;
    padding: 0 12px 12px;
    .van-cell{
      padding-left: 0px;
    }
    /deep/ .van-form {
      .van-cell{
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>